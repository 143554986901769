
main {
  width: 100%;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f3d1a9 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #c69053 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #c69053 !important;
}

@media (min-width: 600px) {
  main {
    width: calc(100vw - var(--sidebar_width) - var(--scrollbar-width));
  }
}

.custom-link {
  text-decoration: none;
}

.custom-link p {
  border-bottom: 1px solid transparent !important;
}

.custom-link:hover p {
  border-bottom: 1px solid currentColor !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.quill_editor {
  width: 100%;
  font-size: large !important;
}

.quill_editor .ql-container {
  height: 200px !important;
}

.quill_editor .ql-toolbar {
  background-color: #f9f9f9;
  border-radius: 6px 6px 0px 0px;
}

.quill_editor .ql-container {
  border-radius: 0px 0px 6px 6px;
}
.delete_Icon {
  color: #e74c3c;
}
.edit_icon {
  color: #fd8e00;
}

.ql-editor {
  font-family: "Roboto,Helvetica,Arial,sans-serif";
  font-size: large;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}

.offerApproved {
  padding: 5px 10px;
  border: 1px solid green;
  border-radius: 10px;
  margin: 0px 5px;
  color: green;
}
.offerPending {
  padding: 5px 10px;
  border: 1px solid blue;
  border-radius: 10px;
  margin: 0px 5px;
  color: blue;
}
.offerRejected {
  padding: 5px 10px;
  border: 1px solid red;
  border-radius: 10px;
  margin: 0px 5px;
  color: red;
}
